<script>
export default {
  name: 'HelpPage',
};
</script>

<template>
  <b-container>
    <b-row align-h='center' align-v='center'>
      <b-col cols=4>
        <h3>Help Page is WIP</h3>
        <b-link to='/loggers'>Click Here</b-link> to return home.
      </b-col>
    </b-row>
  </b-container>
</template>
